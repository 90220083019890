import useAccount from "classes/Accounts/hooks/useAccount";
import MenuToggler from "components/MenuToggler/MenuToggler";
import useTemplate from "hooks/useTemplate";
import React from "react";
import { Link } from "react-router-dom";
import "./PagesGroupPage.css";
import { returnRightsFromName } from "includes/rightType";

export default function PagesGroupPage(props) {
	const { title, pages, curPage } = props;
	// const { account } = useAccount();
	// // returnRightsFromName("AdminAppearance", account.Rights);
	// const checkRight = () => {
	// 	const rigthName = TypeRights.AdminForms;
	// 	try {
	// 		return returnRightsFromName(account.Rights, rigthName);
	// 	} catch (error) {
	// 		return true;
	// 	}
	// };

	if (!pages) return false;
	return (
		<div className="PagesGroupPage PageHeadBar">
			<div className="d-flex align-items-center">
				<MenuToggler
					className="me-3"
					isMenuOpen={props.isMenuOpen}
					setIsMenuOpen={props.setIsMenuOpen}
				/>
				<div className="page-title">{title}</div>
			</div>
			<div className="PagesGroupTiles row">
				{pages.map((a) => (
					<PagesGroupTile
						curPlatform={props.platform}
						isActive={curPage === a.url}
						key={a.url}
						{...a}
					/>
				))}
			</div>
		</div>
	);
}

export function PagesGroupTile(props) {
	const {
		title,
		icon,
		url,
		cName,
		isActive,
		role,
		curPlatform,
		platform,
		appFunction,
	} = props;
	const { isSup } = useAccount();
	const { template } = useTemplate();

	if (
		(role && isSup(role) < 0) ||
		(platform && platform !== curPlatform) ||
		(appFunction &&
			template.getAppFunction &&
			!template.getAppFunction(appFunction) &&
			curPlatform !== "admin")
	)
		return false;

	return (
		<div
			className={
				"PagesGroupTile col-12 col-sm-6 flex-fill" +
				(isActive ? " active" : "")
			}
		>
			<Link
				className={`nav-link d-flex align-items-center ${cName}`}
				to={url}
			>
				<div className="me-4">
					<div
						className="nav-link-page-icon button-bg"
						style={{ backgroundImage: `url(${icon})` }}
					></div>
				</div>
				<div>{title}</div>
			</Link>
		</div>
	);
}
