import React, { Component } from "react";
import Editor from "@toast-ui/react-image-editor";
import "tui-image-editor/dist/tui-image-editor.css";
import "./ImageEditorComponent.css";
import { dataURLtoFile } from "includes/documents_treat";
import { t } from "i18next";
import { useQueryClient } from "react-query";
import ImageEditorTour from "./ImageEditorTour";

const genLocales = () => ({
	Apply: t("ImagesEditor.APPLY"),
	Cancel: t("ImagesEditor.CANCEL"),
	Load: t("ImagesEditor.LOAD"),
	Move: t("ImagesEditor.HAND"),
	Crop: t("ImagesEditor.CROP"),
	Flip: t("ImagesEditor.FLIP"),
	Rotate: t("ImagesEditor.ROTATE"),
	Draw: t("ImagesEditor.DRAW"),
	Shape: t("ImagesEditor.SHAPE"),
	Text: t("ImagesEditor.TEXT"),
	ZoomIn: t("ImagesEditor.ZOOM_IN"),
	ZoomOut: t("ImagesEditor.ZOOM_OUT"),
	History: t("ImagesEditor.HISTORY"),
	Undo: t("ImagesEditor.UNDO"),
	Redo: t("ImagesEditor.REDO"),
	Reset: t("ImagesEditor.RESET"),
	Delete: t("ImagesEditor.DELETE"),
	DeleteAll: t("ImagesEditor.DELETE_ALL"),
});

export default function ImageEditor(props) {
	const QCL = useQueryClient();

	return <ImageEditorComponent {...props} queryClient={QCL} />;
}

export class ImageEditorComponent extends Component {
	constructor(props) {
		super(props);
		this.ref = React.createRef();
		this.imageEditor = null;
		this.saveImage = this.saveImage.bind(this);
		this.handleResize = this.handleResize.bind(this);
		this.handleZoom = this.handleZoom.bind(this);
		this.zoomLevel = 1;
	}

	componentDidMount() {
		window.addEventListener("resize", this.handleResize);

		if (this.props.queryClient) {
			this.addButton({
				text: "Aide",
				tooltip: t("ImagesEditor.HELP"),
				classes: [
					"btn",
					"btn-grey",
					"help-btn",
					"color-primary",
					"image-editor-btn",
				],
				onClick: () => {
					this.imageEditor.getActions().crop.cancel();
					this.props.queryClient.setQueriesData(
						["Helper", "CurrentTour"],
						ImageEditorTour
					);
				},
			});
		}

		this.addButton(
			{
				text: t("ImagesEditor.CANCEL_EDITOR"),
				tooltip: t("ImagesEditor.CANCEL_TOOLTIP"),
				classes: ["btn", "btn-red", "image-editor-btn"],
				onClick: () => this.props.onCancel(),
			},
			true
		);

		this.addButton({
			text: t("ImagesEditor.SAVE"),
			tooltip: t("ImagesEditor.SAVE_TOOLTIP"),
			classes: ["btn", "btn-blue", "image-editor-btn"],
			onClick: () => {
				let crop_btn = document.querySelector(
					".tui-image-editor-menu-crop .tui-image-editor-menu-crop .tie-crop-button .apply"
				);
				if (crop_btn) {
					this.imageEditor
						.crop(this.imageEditor.getCropzoneRect())
						.then(() => {
							this.saveImage();
						});
				} else this.saveImage();
			},
		});

		// Handle fixed crop ratio to 14/11
		document
			.querySelector(".tui-image-editor-item.tie-btn-crop")
			.addEventListener("click", (e) => {
				e.preventDefault();
				e.stopPropagation();
				let img_editor = this.ref.current.getInstance();
				img_editor.ui.crop.actions.modeChange("crop");
				let ratio = this.props.ratio;
				if (!ratio) ratio = 1;
				img_editor.setCropzoneRect(ratio);
			});

		this.loadFile();

		if (this.props.onMount) this.props.onMount();
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.handleResize);
		window.removeEventListener("resize", this.handleZoom);
		this.props.setCurFile(false);
		if (this.props.onUnmount) this.props.onUnmount();
	}

	componentDidUpdate() {
		// this.loadFile();
	}

	loadFile() {
		this.imageEditor = this.ref.current.getInstance();

		let file = this.props.file;
		if (typeof this.props.file === "string")
			file = dataURLtoFile(this.props.file);
		if (this.props.file) {
			this.imageEditor
				.loadImageFromFile(file)
				.then(() => {
					this.imageEditor.ui.activeMenuEvent();
				})
				.catch(() => { });
		}
	}

	handleResize() {
		let contRef = this.props.contRef;
		let isVisible = document.querySelector(".image-editor-cont.d-block");
		if (this.imageEditor && isVisible) {
			let clientWidth = document.body.clientWidth;
			let width = clientWidth * 0.9;
			let height = document.body.clientHeight * 0.8;

			if (contRef && contRef.current) {
				width = contRef.current.parentElement.clientWidth;
				height = contRef.current.parentElement.clientHeight - 104;
			}
			this.imageEditor.resizeCanvasDimension({
				width: width,
				height: height,
			});
		}
	}

	addButton(props, prepend) {
		let base_classes = [
			"Button",
			"btn",
			"tui-image-editor-item",
			"normal",
			"w-auto",
		];
		let classes = base_classes.concat(props.classes ? props.classes : "");
		let btn = document.createElement("li");

		btn.classList.add(...classes);
		btn.setAttribute("tooltip-content", props.tooltip);
		btn.innerText = props.text;
		if (props.html) btn.innerHTML = props.html;
		if (props.onClick) btn.addEventListener("click", props.onClick);
		if (!prepend)
			document.querySelector(".tui-image-editor-menu").appendChild(btn);
		else document.querySelector(".tui-image-editor-menu").prepend(btn);
	}

	handleZoom(e) {
		if (e.deltaY > 0) this.zoomLevel += 0.3;
		else if (this.zoomLevel - 0.3 > 0.5) this.zoomLevel -= 0.3;
		else this.zoomLevel = 0.5;
		this.imageEditor.zoom(
			{
				x: e.offsetX,
				y: e.offsetY,
				zoomLevel: this.zoomLevel,
			},
			e.target.offsetWidth / 2,
			e.target.offsetHeight / 2,
			this.zoomLevel
		);
	}

	saveImage() {
		let img = this.imageEditor.toDataURL();
		if (this.props.onSave) this.props.onSave(img);
		this.props.setCurFile(false);
		return img;
	}

	render() {
		let contRef = this.props.contRef;
		let maxWidth = document.body.clientWidth * 0.8;
		let maxHeight = document.body.clientHeight * 0.8;

		if (contRef && contRef.current) {
			maxWidth = contRef.current.parentElement.clientWidth;
			maxHeight = contRef.current.parentElement.clientHeight;
		}
		return (
			<div
				className={"image-editor-cont " + this.props.className}
				onWheel={this.handleScroll}
			>
				<Editor
					ref={this.ref}
					includeUI={{
						theme: {
							"loadButton.borderColor": "transparent",
							"downloadButton.display": "none",
							"zoomButton.display": "none",
							"header.display": "none",
						},
						menu: ["crop", "flip", "rotate", "draw", "text"],
						initMenu: "crop",
						uiSize: {
							width: "100%",
							height: "100%",
						},
						menuBarPosition: "bottom",
						locale: genLocales(),
					}}
					selectionStyle={{
						cornerSize: 20,
						rotatingPointOffset: 70,
					}}
					cssMaxWidth={maxWidth}
					cssMaxHeight={maxHeight}
					usageStatistics={false}
					actions={[""]}
				/>
			</div>
		);
	}
}
