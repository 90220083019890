import useAccount from "classes/Accounts/hooks/useAccount";
import useDisplay from "hooks/useDisplay";
import PostsFetcher from "classes/Posts/PostsFetcher";
import PageHeadBar from "components/HeadBar/PageHeadBar";
import { t } from "i18next";
import React, { Component, useEffect, useState } from "react";
import PostsList from "../PostsList/PostsList";
import { useHistory } from "react-router-dom";
import "./PostsPage.css";
import useEditing from "components/EditDrawer/useEditing";
import PostObject from "classes/Posts/PostObject";
import formatDate from "includes/formatDate";
import PostEditDrawer from "../PostEditDrawer/PostEditDrawer";
import { useQuery, useQueryClient } from "react-query";
import FormFiller from "Pages/Forms/components/FormFiller/FormFiller";
import PostsStateSelector from "../PostsStateSelector/PostsStateSelector";
import genRequest from "includes/request";
import AsyncPostPreview from "../PostPreview/AsyncPostPreview";
import HighlightedCategories from "components/HighlightedCategories/HighlightedCategories";
import { useAccountContext } from "providers/AccountProvider";
import { getRightFromTypeId } from "includes/mappingTypeIdName";
import { TypeRights } from "includes/rightType";
import { RssPostsPage } from "Pages/RSS/rss-posts.page";

export default function PostsPage(props) {
	const { account, isAdmin } = useAccount();
	const { userOrAdmin } = useAccountContext();
	const { platform } = props;
	const [curObj, setCurObj] = useState(false);
	const [CurPost, setCurPost] = useState(false);
	const { editing, setEditing } = useEditing();
	const [CurSite, setCurSite] = useState(false);
	const [favSites, setFavSites] = useState([]);
	const [hideFavSites, setHideFavSites] = useState(true);
	const [DispCats, setDispCats] = useState([]);
	const { display, setDisplay } = useDisplay(props.platform === "admin" ? "table" : "tiles");
	const [ShowFavs, setShowFavs] = useState(false);
	const [editAction, setEditAction] = useState(false);
	const [fillingForm, setFillingForm] = useState(false);
	const [DispState, setDispState] = useState("published");
	const [keyChild, setKeyChild] = useState(new Date());
	const [showRss, displayRss] = useState(false);
	const history = useHistory();
	const [Sorting] = useState({
		sortBy: "PriorityTo",
		reverse: false,
	});
	const [search, setSearch] = useState("");
	const QCL = useQueryClient();
	const { isMenuOpen, setIsMenuOpen } = props;
	const LastDisplay = React.useRef();
	const LastPlatform = React.useRef();

	useEffect(() => {
		let timer1;
		LastDisplay.current = props.display;
		if (props.platform !== LastPlatform.current) {
			if (props.platform === "admin" && display !== "table")
				setDisplay("table");
			else if (props.platform !== "admin" && display !== "tiles")
				setDisplay("tiles");
		}
		LastPlatform.current = props.platform;
		if (props.platform !== "admin") {
			setDispState("published");
			timer1 = setTimeout(() => {
				setKeyChild(new Date());
			}, 1);
		}
		return () => {
			clearTimeout(timer1);
		}
	}, [platform]);

	const { data, error, isLoading } = useQuery(["FavoritesSites"], () =>
		genRequest(`Me/GetFavoritesSites`)
	);

	useEffect(() => {
		const rigthName = getRightFromTypeId(props.typeId);
		if (
			!isLoading &&
			userOrAdmin === "admin" &&
			data.length > 0 &&
			(rigthName === TypeRights.AdminNews ||
				rigthName === TypeRights.AdminAds ||
				rigthName === TypeRights.AdminProAds)
		) {
			const siteId = data.map((site) => site.SiteId);
			setFavSites(siteId);
			setHideFavSites(false);
		}
	}, [data, userOrAdmin]);

	const handleAddingNewPost = (new_post) => {
		displayRss(false);
		setCurObj(new_post);
		setEditAction("add");
		setEditing(true);
	};

	function handleAdd() {
		let new_post = new PostObject({
			EmployesId: account.EmployesId,
			CompanyId: account.CompanyId,
			SiteId: account.SiteId,
			PublishedDate: formatDate(),
			LastChanged: formatDate(),
			PriorityTo: null,
			PostTypeId: props.typeId,
			Adress: window.location?.origin,
			ValidationModeration: account.ByPass_Moderation,
			account: account,
		});
		handleAddingNewPost(new_post);
	}

	function handleModify(post, action, notify) {
		if (!post) return false;
		if (props.onModify) return props.onModify(post, action, notify);
		post.CompanyId = account.CompanyId;
		post.Adress = post.Adress || window.location?.origin;
		if (!editAction || action) setEditAction(action ? action : "modify");
		let post_obj = new PostObject({
			...post,
			Draft: false,
			account: account,
			NotifyPost: notify,
		});
		setCurObj(post_obj);
		setEditing(true);
	}

	function handleDuplicate(post) {
		if (!post) return false;
		post.CompanyId = account.CompanyId;
		post.Adress = window.location?.origin;
		setEditAction("add");
		let post_obj = new PostObject({
			...post,
			Draft: false,
			account: account,
			NotifyPost: true,
		});
		post_obj.fetchForDuplicate().then(() => {
			setCurObj(post_obj);
			setEditing(true);
		});
	}

	function handlePublish(post) {
		handleModify(post, "publish", true);
	}

	function handleReject() {
		setEditAction("reject");
	}

	function handleCloseEdit() {
		setEditAction(false);
		setCurObj(false);
		setEditing(false);
		setDispState("published");
		QCL.resetQueries(["RetrievePosts"]);
	}

	function toggleFavs() {
		if (DispState !== "published") setDispState("published");
		setShowFavs(!ShowFavs);
	}

	function toggleDrafts() {
		if (ShowFavs) setShowFavs(false);
		if (DispState !== "drafts") setDispState("drafts");
		else setDispState("published");
	}

	function dispDrafts() {
		if (curObj && editing) handleCloseEdit();
		setShowFavs(false);
		setDispState("drafts");
	}

	function selectCategory(cat) {
		let cats = [...DispCats];
		let cat_id = cat.id || cat.CategoryId;
		let check = DispCats?.indexOf(cat_id);

		if (check === -1) cats.push(cat_id);
		else
			cats = cats
				.map((a) => {
					if (a === cat_id) return false;
					return a;
				})
				.filter((a) => a);
		setDispCats(cats);
	}

	function resetCatsFilter() {
		setDispCats([]);
	}

	function fillForm(form) {
		setFillingForm(form);
		setEditing(true);
	}

	function handleCloseFormFill() {
		setFillingForm(false);
		setEditing(false);
	}

	const getFiltersCount = () => {
		let count = 0;
		count += DispCats?.length;

		if (CurSite)
			if (CurSite?.length) count += CurSite?.length;
			else if (CurSite?.value !== 0) count += 1;
		return count;
	};

	return (
		<div className="PostsPage h-100 w-100 d-flex flex-column">
			<PageHeadBar
				showRss={showRss}
				typeId={props.typeId}
				logo={props.headBarLogo}
				siteSelect={isAdmin() ? setCurSite : false}
				curSite={CurSite}
				favSites={favSites}
				favSitesSelect={isAdmin() ? setFavSites : false}
				handleAdd={handleAdd}
				hideFavSites={hideFavSites}
				addTitle={props.addTitle}
				search={setSearch}
				title={props.title}
				favState={ShowFavs}
				menuToggler={props.menuToggler}
				display={display}
				setDisplay={setDisplay}
				isMenuOpen={isMenuOpen}
				setIsMenuOpen={setIsMenuOpen}
				categories={display !== "table"}
				dispCats={DispCats}
				selectCategory={selectCategory}
				resetCatsFilter={resetCatsFilter}
				catsType={"post-" + props.typeId}
				catsQueryKey={[
					"Categories",
					"CompanyTyped",
					account.CompanyId,
					"post-",
					props.typeId,
				]}
				catsQueryFn={() =>
					genRequest(
						"Categories/CompanyTyped/" +
						account.CompanyId +
						"/post-" +
						props.typeId
					)
				}
				filters={props.filters !== false}
				filtersCount={getFiltersCount()}
				buttons={[
					{
						title: t("Commons.FAVORITES"),
						classes: ShowFavs ? "btn-primary" : "",
						onClick: toggleFavs,
					},
					{
						title: t("Posts.DRAFT_BTN"),
						classes: DispState === "drafts" ? "btn-primary" : "",
						onClick: toggleDrafts,
						enabled: props.drafts !== false && props.add,
					},
				]}
			/>
			{platform === "admin" &&
				props.moderation !== false &&
				props.typeId !== 5 && props.typeId !== 3 && (
					<PostsStateSelector
						typeId={props.typeId}
						state={DispState}
						changeState={setDispState}
						displayRss={displayRss}
						showRss={showRss}
					/>
				)}
			<div className="PostPageBody d-flex w-100 h-100 overflow-hidden">
				{showRss ? (
					<RssPostsPage handleAddingNewPost={handleAddingNewPost} />
				) : (
					<>
						{!curObj && !fillingForm && (
							<PostsFetcher
								{...props}
								userOrAdmin={userOrAdmin}
								childComponent={PostPageBody}
								sortBy={Sorting.sortBy}
								reverseSort={Sorting.reverse}
								saveds={ShowFavs}
								search={search}
								favSites={favSites}
								siteId={CurSite?.value}
								editing={editing}
								queryFn={props.queryFn}
								queryKey={props.queryKey}
								state={DispState}
								childProps={{
									userOrAdmin: userOrAdmin,
									display,
									typeId: props.typeId,
									handleModify,
									handlePublish,
									handleReject:
										props.moderation !== false
											? handleReject
											: false,
									editAction,
									forceCurId: parseInt(props.forceCurId),
									dispCats: DispCats,
									selectCategory,
									baseUrl: props.baseUrl,
									search,
									curPost: CurPost,
									setCurPost,
									key: keyChild,
									fillingForm,
									QCL,
									setFillingForm: fillForm,
									dispState: DispState,
									history,
									account,
									isAdmin: isAdmin(),
									...props,
								}}
							/>
						)}
						{!curObj && (
							<AsyncPostPreview
								type={props.type && props.type}
								postId={CurPost.post?.PostId}
								prevPost={CurPost?.prev}
								nextPost={CurPost?.next}
								canModify={props.modify !== false}
								canComment={props.canComment}
								commentBtnTxt={props.commentBtnTxt}
								showComments={props.showComments}
								handleModify={handleModify}
								handleReject={handleReject}
								handlePublish={handlePublish}
								handleDuplicate={handleDuplicate}
								resetCur={() => setCurPost(false)}
								fillingForm={fillingForm}
								setFillingForm={setFillingForm}
								dispState={DispState}
								editButtons={props.editButtons !== false}
								platform={props.platform}
								moderation={props.moderation}
								firstShow={CurPost?.firstShow}
							// float={document.body.clientWidth > 1200 && document.body.clientWidth < 1300 ? "right" : false}
							/>
						)}
					</>
				)}
			</div>
			{curObj && !fillingForm && (
				<PostEditDrawer
					typeId={props.typeId}
					onClose={handleCloseEdit}
					post={curObj}
					isOpen={editing ? true : false}
					account={account}
					title={props.addTitle || props.editAddTitle}
					dispState={DispState}
					editAction={editAction}
					dispDrafts={dispDrafts}
					initQueries={[
						{
							queryKey: ["PostEdit", "FetchForEdit", Date.now()],
							queryFn: () => curObj.fetchForEdit(),
						},
					]}
					postType={props.postType}
				/>
			)}
			{fillingForm && (
				<FormFiller
					form={fillingForm}
					isOpen={true}
					onClose={handleCloseFormFill}
				/>
			)}
		</div>
	);
}

class PostPageBody extends Component {
	constructor(props) {
		super(props);

		this.handlePostClick = this.handlePostClick.bind(this);
		this.getFirstDisplayed = this.getFirstDisplayed.bind(this);
		this.getOtherPosts = this.getOtherPosts.bind(this);
		this.resetCur = this.resetCur.bind(this);
	}

	componentDidMount() {
		if (!this.props.curPost) {
			let post = this.getFirstDisplayed(parseInt(this.props.forceCurId));
			this.handlePostClick({ datas: post });
		}
	}

	componentDidUpdate(props) {
		if (
			props.dispState !== this.props.dispState ||
			props.userOrAdmin !== this.props.userOrAdmin
		) {
			if (!this.props.posts?.length) {
				this.resetCur();
				return;
			}
			let post = this.getFirstDisplayed(parseInt(this.props.forceCurId));
			this.handlePostClick({ datas: post });
		}
	}

	changeCurPost(post) {
		if (!post) {
			this.props.history.push(
				this.props.baseUrl ? this.props.baseUrl : ""
			);
			return false;
		}
		if (this.props.curPost?.post?.PostId !== post.post?.PostId) {
			this.props.history.push(
				(this.props.baseUrl ? this.props.baseUrl + "/" : "") +
				post.post?.PostId.toString()
			);
			this.props.setCurPost(post);
		}
	}

	checkDate(post) {
		let date = formatDate();
		return (
			date >= post.PublishedDate ||
			this.props.dispState !== "published" ||
			this.props.isAdmin ||
			post.EmployesId === this.props.account?.EmployesId
		);
	}

	getFirstDisplayed(forceCurId) {
		let posts = this.props.posts;
		if (!posts?.length || document.body.clientWidth < 1200) return false;
		if (forceCurId) {
			let ret = posts.find(
				(a) => this.checkDate(a) && a.PostId === forceCurId
			);
			if (ret) return ret;
		}
		let ret = posts.find((a) => this.checkDate(a));
		if (ret) return ret;
	}

	getOtherPosts(postId) {
		let posts = this.props.posts;
		if (!posts?.length) return false;
		for (let x = 0; x < posts.length; x++)
			if (posts[x].PostId === postId) {
				return {
					prev: posts[x - 1]
						? () => this.handlePostClick({ datas: posts[x - 1] })
						: false,
					next: posts[x + 1]
						? () => this.handlePostClick({ datas: posts[x + 1] })
						: false,
				};
			}
	}

	handlePostClick(item) {
		if (!item?.datas)
			return this.props.setCurPost({
				post: false,
				prev: false,
				next: false,
				firstShow: true,
			});
		const post = item.datas;
		let others = this.getOtherPosts(post.PostId);
		this.props.setCurPost({
			post,
			...others,
			firstShow: this.props.curPost?.post ? false : true,
		});
	}

	resetCur() {
		this.props.setCurPost({ CurPost: false });
	}

	render() {
		return (
			<div
				className={
					"w-100 d-flex flex-column h-100 overflow-hidden gap-2" +
					(!this.props.curPost ? " no-cur" : "")
				}
			>
				{this.props.display !== "table" && (
					<HighlightedCategories
						typeId={"post-" + this.props.typeId}
						onItemClick={(item) => {
							this.props.selectCategory(item);
						}}
						selected={this.props.dispCats}
					/>
				)}
				<PostsList
					curPost={this.props.curPost.post}
					posts={this.props.posts}
					display={this.props.display}
					typeId={this.props.typeId}
					handlePostClick={this.handlePostClick}
					handleModify={this.handleModify}
					dispCats={this.props.dispCats}
					search={this.props.search}
					state={this.props.dispState}
					isAdmin={this.props.isAdmin}
					account={this.props.account}
					checkbox={this.props.checkbox}
				/>
			</div>
		);
	}
}
