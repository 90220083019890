import React, { useState, createRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpandAlt } from "@fortawesome/free-solid-svg-icons";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./PostImages.css";
import { t } from "i18next";
import AsyncPostImages from "./AsyncPostImages";

function PostImages(props) {
	const [isExtended, setIsExtended] = useState(false);
	const [imageError, setImagesError] = useState(false);
	const [showMobileImg, setShowMobileImg] = useState(false);
	const slider_ref = createRef();

	useEffect(() => {
		if (imageError) {
			setShowMobileImg(true);
		}
		return () => {
			setShowMobileImg(false);
		}
	}, [imageError]);

	if (props.async)
		return (
			<AsyncPostImages
				{...props}
			/>
		);

	function toggleImageExtend() {
		let new_state = !isExtended;
		setIsExtended(new_state);
		if (props.handleExtend)
			props.handleExtend(new_state);
	}

	function handleImageError() {
		setImagesError(true);
	}

	function goToSlide(index) {
		if (slider_ref?.current)
			slider_ref.current?.slickGoTo(index);
	}

	let { images, post } = props;

	let extend = props.extend !== false ? true : false;

	if (!images?.length || imageError) {
		if (props.forceImage === true)
			return (<div className="PostImages p-4" />);
		else
			return (false);
	}

	const get_url = (img, thumb) => {
		if (props.useDocumentFile || img.DocumentFile) {
			if (img.DocumentFile?.indexOf("base64") > -1)
				return (img.DocumentFile);
			return ("data:image/png;base64," + img.DocumentFile);
		}
		return (thumb ? img.Url_Mobile : img.Url_Web);
	};

	return (
		<div className={"post-images-cont d-flex justify-content-center flex-column w-100" + (isExtended ? " extended" : "") + (imageError ? " image-error" : "")} >
			<div key={post.PostId} className="post-images-slider">
				<Slider
					ref={slider_ref}
					arrows={true}
					autoplay={false}
					pauseOnHover={false}
					autoplaySpeed={500}
					speed={500}
					infinite={true}
					usageStatistics={false}
				>
					{
						images.map((img, key) => {
							if (img.IsDeleted && !props.showDeleted)
								return (false);
							let url = get_url(img, true);
							let webUrl = get_url(img, false);
							return (
								<div key={post.PostId || key} className="post-image d-flex justify-content-center">
									{showMobileImg ? <picture>
										<source srcSet={url} media="(max-width: 800px)" />
										<source srcSet={webUrl} />
										<img src={webUrl} loading="lazy" sizes="(max-width: 800px) 500px, 1080px" alt={"Post Image - " + post.Title} key={"slide-" + img.DocumentId} onError={handleImageError} />
									</picture> : <img src={url} loading="lazy" alt={"Post Image - " + post.Title} key={"slide-" + img.DocumentId} onError={handleImageError} />
									}
								</div>
							);
						})
					}
				</Slider>
				{
					extend ?
						<div className="extend-image-btn cursor-pointer" title={!isExtended ? t("Posts.EXTEND_IMAGES") : t("Commons.CLOSE")} onClick={toggleImageExtend}>
							<FontAwesomeIcon icon={isExtended ? faTimesCircle : faExpandAlt} />
						</div>
						:
						false
				}
			</div>
			{
				images.length > 1 ?
					<div className="post-images-preview mt-2 gap-2">
						{
							images.map((img, index) => {
								if (img.IsDeleted && !props.showDeleted)
									return (false);
								let url = get_url(img, true);
								return (
									<div onClick={() => goToSlide(index)} key={"prev-" + (img.DocumentId ? img.DocumentId : index)}>
										<img loading="lazy" draggable={false} alt={"Post Image - " + post.Title} src={url} onError={handleImageError} />
									</div>
								);
							})
						}
					</div>
					:
					false
			}
		</div>
	);
}

export default PostImages;
