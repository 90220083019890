import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import useAccount from "classes/Accounts/hooks/useAccount";
import { QuerySelect } from "components/Inputs/CustomAsyncSelect/CustomAsyncSelect";
import { QueryTagsList } from "components/Tags/TagsList/TagsList";
import genRequest from "includes/request";
import { t } from "i18next";
import "./FiltersDrawer.css";
import useOutsideAlerter from "hooks/useOutsideAlerter";
import { useQuery } from "react-query";
import { FilterFavSites } from "./favSitesFilter.component";
import usePlatform from "hooks/usePlatform";
import { useAccountContext } from "providers/AccountProvider";

export default function FiltersDrawer(props) {
	const { account, isAdmin } = useAccount();
	const [favSites, setFavSites] = useState([]);
	const [favSiteVisible, setFavSiteVisible] = useState(false);
	const { userOrAdmin } = useAccountContext();

	const cont_ref = useRef();

	const { data, error, isLoading } = useQuery(["FavoritesSites"], () =>
		genRequest(`Me/GetFavoritesSites`)
	);

	useEffect(() => {
		if (userOrAdmin) {
			setFavSiteVisible(userOrAdmin === "admin");
		}
		return () => {
			setFavSiteVisible(false);
		}
	}, [userOrAdmin]);

	useOutsideAlerter(cont_ref, handleClose);

	const getCategoriesReq = () => {
		if (props.catsType)
			return {
				queryKey: [
					"Categories",
					"CompanyTyped",
					account.CompanyId,
					props.catsType,
				],
				queryFn: () =>
					genRequest(
						"Categories/CompanyTyped/" +
						account.CompanyId +
						"/" +
						props.catsType
					),
			};
		return {
			queryKey: props.catsQueryKey
				? props.catsQueryKey
				: ["Categories", "Company", account.CompanyId],
			queryFn: props.catsQueryFn
				? props.catsQueryFn
				: () => genRequest("Categories/Company/" + account.CompanyId),
		};
	};

	// useQuery

	useEffect(() => {
		if (!isLoading) {
			setFavSites(data);
		}
		return () => {
			setFavSites([]);
		}
	}, [data]);

	//Me/GetFavoritesSites

	const anim = {
		hidden: {
			height: "0px",
			padding: "0px",
			overflow: "hidden",
			transition: {
				ease: "easeOut",
				duration: 0.2,
			},
		},
		show: {
			height: "auto",
			padding: "15px",
			opacity: 1,
			transition: {
				ease: "easeOut",
				duration: 0.2,
			},
		},
	};

	function handleClose(e) {
		if (e.target?.className.indexOf("filter-button") > -1) return false;
		if (props.onClose) props.onClose();
	}

	if (props.isOpen === false) return false;

	return (
		<motion.div
			variants={anim}
			initial="hidden"
			animate={props.isOpen ? "show" : "hidden"}
			className={`FiltersDrawer d-flex flex-column gap-3${props.isOpen ? " open" : ""
				}${props.className ? " " + props.className : ""}`}
			ref={cont_ref}
		>
			<h3>{t("Commons.FILTERS")}</h3>
			{props.siteSelect !== false && (
				<>
					<div className="sites-drop-cont">
						<QuerySelect
							title={t("Sites.SITES")}
							className="light-bg"
							value={props.curSite}
							onChange={props.siteSelect}
							closeMenuOnSelect={true}
							placeholder={t("Commons.ALL_SITES")}
							all={{
								label: t("Commons.ALL_SITES"),
								value: 0,
							}}
							queryKey={["Sites"]}
							queryFn={() =>
								genRequest(
									"Sites/SitesOfCompany/" + account.CompanyId
								)
							}
							treat={(item) => ({
								value: item.SiteId,
								label: item.Name + " - " + item.Town,
							})}
							initialValue={props.curSite}
						/>
					</div>
					{favSites.length > 0 &&
						favSiteVisible &&
						isAdmin() &&
						!props.hideFavSites && (
							<FilterFavSites
								data={favSites}
								favSitesSelected={props.favSites}
								curSite={props.curSite}
								setCurSite={props.siteSelect}
								favSitesSelect={props.favSitesSelect}
							/>
						)}
				</>
			)}

			{props.categories !== false && (
				<div>
					<QueryTagsList
						title={t("Commons.CATEGORIES")}
						reset={props.resetCatsFilter}
						className="categories-list"
						{...getCategoriesReq()}
						treat={props.catsTreat ? props.catsTreat : false}
						onItemClick={props.selectCategory}
						selected={props.dispCats}
						color={props.catsColor}
					// unstyled={true}
					/>
				</div>
			)}
			<FilterChilds items={props.filterChilds} />
		</motion.div>
	);
}

const FilterChilds = ({ items }) => {
	if (!items?.length) return false;

	let dom = items.map((a, key) => {
		if (a.enabled === false) return false;
		return <Child key={key} {...a} />;
	});
	return <div className="d-flex gap-4 flex-column">{dom}</div>;
};

const Child = (child_props) => {
	const { component, title, props } = child_props;

	if (!component) return false;
	return (
		<div>
			{title?.length > 0 && <div>{title}</div>}
			<div>
				<child_props.component {...props} />
			</div>
		</div>
	);
};
