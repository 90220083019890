import useAccount from "classes/Accounts/hooks/useAccount";
import formatDate, { dateAdd } from "includes/formatDate";
import genRequest from "includes/request";
import React, { useState } from "react";
import { useQuery } from "react-query";
import "moment/locale/fr";
import "./MostLikedPosts.css";
import { t } from "i18next";
import LoadCont from "components/LoadCont/LoadCont";
import TileImage from "Pages/Posts/components/Images/TileImage/TileImage";
import AuthorPreview from "components/User/AuthorPreview/AuthorPreview";
import Button from "components/Button/Button";

export default function MostLikedPosts(props) {
	const { account } = useAccount();
	const [display, setDisplay] = useState("month");

	const fetchPosts = () => {
		let date = formatDate(getDate(), "Y-M-D");
		let now = formatDate(new Date(), "Y-M-D");
		// let req = genRequest(`Analytics/ListedMostLikedNewsOfCompanyDuringPeriod/${max}/${account.CompanyId}/${date}/${now}`);
		let req = genRequest("Stats/Popular_Post_Of_PostType_In_Period_For_Company", {
			PostTypeId: 1,
			TargetCompanyId: account.CompanyId,
			FromDate: date,
			ToDate: now,
		}, "POST");

		return (req);
	};

	const getDate = () => {
		let date = new Date();
		if (display === "day")
			return (date.setHours(0, 0, 0, 0));
		else if (display === "week")
			return (dateAdd(date, { weeks: -1 }));
		else if (display === "month")
			return (dateAdd(date, { months: -1 }));
		else if (display === "all")
			return (dateAdd(date, { years: -3 }));
		return (date);
	};

	const getPosts = () => {
		return data.filter(({ Key }) => Key.ValidationModeration && !Key.ModerationComment && !Key.Draft).map(({ Key }) => Key).reverse();
	};

	const { isLoading, data } = useQuery(
		["MostLikedNewsOfCompanyDuringPeriod", account.CompanyId, display],
		fetchPosts
	);

	// let posts = getPosts(data);

	return (
		<div className="MostLikedPosts">
			<div className="head d-flex flex-wrap mb-2 justify-content-between align-items-center">
				<div className="me-2">
					<div className="stat-module-title">{t("Analytics.HOT_POSTS")}</div>
					<div className="sub-info">
						{t("Analytics.postscount", { count: isLoading ? "" : getPosts().length })}
					</div>
				</div>
				<div className="ms-auto head-btns buttons-group">
					<Button onClick={() => setDisplay("all")} className={"unstyled" + (display === "all" ? " active" : "")}>{t("Analytics.ALL")}</Button>
					<Button onClick={() => setDisplay("month")} className={"unstyled" + (display === "month" ? " active" : "")}>{t("Analytics.MONTH")}</Button>
					<Button onClick={() => setDisplay("week")} className={"unstyled" + (display === "week" ? " active" : "")}>{t("Analytics.WEEK")}</Button>
					<Button onClick={() => setDisplay("day")} className={"unstyled" + (display === "day" ? " active" : "")}>{t("Analytics.DAY")}</Button>
				</div>
			</div>
			{
				isLoading ?
					<div className="d-flex justify-content-center">
						<LoadCont text={t("Commons.LOADING")} />
					</div>
					:
					<PostsList max={props.max} posts={getPosts(data)} onClick={props.onClick} />
			}
		</div>
	);
}

function PostsList({ posts, onClick, max }) {

	const handleClick = (post) => {
		if (onClick)
			onClick(post);
	};

	if (!posts?.length)
		return (
			<div className="list-ph">
				{t("Analytics.NO_POSTS")}
			</div>
		);

	let list = posts;
	if (max)
		list = list.slice(0, max);
	return (
		<div className="LikedPostsList">
			{
				list.map((a) => <PostTile key={a.PostId} onClick={() => handleClick(a)} post={a} />)
			}
			{
				list?.length < posts?.length &&
				<div className="list-ph w-100">
					{
						t(
							"Analytics.others",
							{ count: posts?.length - list?.length }
						)
					}
					{/* {t("Analytics.AND")}  {t("Analytics.OTHERS")} */}
				</div>
			}
		</div>
	);
}

function PostTile({ post, onClick }) {
	return (
		<div className={"MostLikedPostTile" + (onClick ? " cursor-pointer" : "")} onClick={onClick}>
			<div className="d-flex overflow-hidden w-100 me-2">
				<TileImage postId={post.PostId} postTypeId={post.PostTypeId} />
				<div className="ms-2 overflow-hidden w-100">
					<div className="tile-text font-bold">
						{post.Title}
					</div>
					<AuthorPreview
						className="posts-list-author-preview mt-1"
						employeId={post.EmployesId}
						imgWidth={20}
						format={[
							"EmployeFirstName EmployeLastName / WFU",
							{ date: post.PublishedDate }
						]}
						modifiers={{
							EmployeLastName: (item) => item.toUpperCase()
						}}
					/>
				</div>
			</div>
			<div className="text-end">
				<div>
					<b>{post.LikesCount}</b>&nbsp;{t("Posts.LIKES", { count: post.LikesCount })}
				</div>
				<div>
					<b>{post.ViewCount}</b>&nbsp;{t("Posts.VIEWS", { count: post.ViewCount })}
				</div>
			</div>
		</div>
	);
}
