import React, { useState } from "react";
import useAccount from "classes/Accounts/hooks/useAccount";
import genRequest from "includes/request";
import { useQuery } from "react-query";
import UserImage from "../Image/UserImage";
import { usePopper } from "react-popper";
import { AsyncUserCard } from "../Card/UserCard";
import "./AuthorPreview.css";
import "moment/locale/fr";
import { t } from "i18next";
import moment from "moment";
import Moment from "react-moment";
import { useHistory } from "react-router-dom";

const AuthorPreview = React.memo((props) => {
	const {
		employeId,
		className,
		format,
		noPopper,
		imgWidth,
		imgRes,
		timeout,
		modifiers,
	} = props;
	const { account } = useAccount();
	const history = useHistory();
	const [referenceElement, setReferenceElement] = useState(null);
	const [popperElement, setPopperElement] = useState(null);
	const [TM, setTM] = useState(false);
	const { styles, attributes } = usePopper(referenceElement, popperElement, {
		placement: "bottom-end",
		strategy: "fixed",
	});
	const [isOpen, setIsOpen] = useState(false);

	const handleShowPopper = () => {
		resetTm(
			setTimeout(
				() => {
					setIsOpen(true);
				},
				timeout ? timeout : 500
			)
		);
	};

	const closePopper = () => {
		setIsOpen(false);
		resetTm();
	};

	const togglePopper = (e) => {
		e.stopPropagation();
		resetTm();
		setIsOpen(!isOpen);
	};

	const goToContact = (e) => {
		history.push("/contacts/?employeId=" + employeId);
	};

	const resetTm = (new_tm) => {
		clearTimeout(TM);
		setTM(new_tm ? new_tm : false);
	};

	const applyModifier = (key, value) => {
		if (!modifiers || !modifiers[key]) return value;
		return modifiers[key](value);
	};

	const fetchMinimalEmploye = () =>
		genRequest("Employes/MinimalContactEmployesFromId/" + employeId);

	const { isLoading, data, error } = useQuery(
		["Employes", "MinimalContactEmployesFromId", employeId],
		fetchMinimalEmploye,
		{
			enabled: employeId ? true : false,
			onSettled: props.onSettled,
		}
	);

	if (error) return false;

	if (isLoading)
		return (
			<div className={"user-card loading author-preview " + className}>
				<div className="w-100"></div>
				<div className="w-50"></div>
			</div>
		);

	if (!data) return false;

	data.CompanyId = account.CompanyId;

	return (
		<div className="d-flex w-auto" ref={setReferenceElement}>
			<div
				className={
					"user-card author-preview " +
					(!noPopper ? "cursor-pointer " : "") +
					className
				}
				onClick={goToContact}
				onMouseEnter={handleShowPopper}
				onMouseLeave={closePopper}
			>
				<div className="d-flex align-items-center gap-1">
					<UserImage
						companyId={data.CompanyId}
						employeId={data.EmployeId}
						width={imgWidth}
						res={imgRes}
						title={
							noPopper
								? data.FirstName +
								" " +
								data.LastName
								: ""
						}
					/>
					<div className="user-infos">
						{format
							? format.map((a, index) => {
								let ret = a;
								for (let x in data) {
									if (typeof a === "string")
										ret = ret.replace(
											new RegExp("\\b" + x + "\\b"),
											applyModifier(x, data[x])
										);
									else if (
										typeof a.date !== "undefined"
									) {
										if (!a.date) return false;
										ret = (
											<DatePreview
												{...(typeof a.date ===
													"object"
													? a.date
													: a)}
											/>
										);
									} else ret = a;
								}
								return <div key={index}>{ret}</div>;
							})
							: false}
					</div>
				</div>
				{isOpen && data && !noPopper && (
					<div
						className="author-popper"
						ref={setPopperElement}
						onMouseLeave={closePopper}
						style={styles.popper}
						{...attributes.popper}
					>
						<AsyncUserCard employeId={data.EmployeId} />
					</div>
				)}
			</div>
		</div>
	);
});
AuthorPreview.displayName = "AuthorPreview";

export function DatePreview(props) {
	const { date, diff, format } = props;
	let date_diff = moment().diff(date, "days", true);
	let max_diff = diff || 1.5;
	let disp_format = format || t("Events.DATE_TIME_AT_FORMAT");

	return (
		<div className="published-date">
			<Moment
				{...props}
				locale={t("Code")}
				titleFormat={disp_format}
				withTitle
				fromNow={date_diff <= max_diff}
				format={date_diff > max_diff ? disp_format : false}
			>
				{date}
			</Moment>
		</div>
	);
}

export default AuthorPreview;
