import React, { useState } from "react";
import { motion } from "framer-motion";
import Markdown from "markdown-to-jsx";
import useAccount from "classes/Accounts/hooks/useAccount";
import AuthorPreview from "components/User/AuthorPreview/AuthorPreview";
import "./PostPreview.css";
import { t } from "i18next";
import PostPreviewBottomBar from "./components/BottomBar/PostPreviewBottomBar";
import PostContent from "./components/PostContent/PostContent";
import PostResponse from "./components/PostResponse/PostResponse";
import PostImages from "../Images/PostImages/PostImages";
import PostComments from "./components/PostComments/PostComments";
import PostDocumentsDrawer from "./components/PostDocumentsDrawer/PostDocumentsDrawer";
import PostPreviewTopBar from "./components/TopBar/PostPreviewTopBar";
import PostLinkedForms from "./components/PostLinkedForm/PostLinkedForm";
import EditButtons from "./components/EditButtons/EditButtons";
import BackButton from "components/Button/BackButton";
import PostCategories from "./components/PostCategories/PostCategories";
import PostStats from "../PostStats/PostStats";
// import PostMetaTags from "./components/MetaTags/PostMetaTags";

function PostPreview(props) {
	const { account, isAdmin } = useAccount();
	const [isExtended, setIsExtended] = useState(
		document.body.clientWidth <= 1200 || props.forceExtend
	);
	const [isDocsExtended, setIsDocsExtended] = useState(false);
	const [isImagesExtended, setIsImagesExtended] = useState(false);
	const [isCommentsExtended, setIsCommentsExtended] = useState(false);
	const [isStatsExtended, setIsStatsExtended] = useState(false);
	const [firstShow, setFirstShow] = useState(
		typeof props.firstShow !== "undefined" ? props.firstShow : true
	);
	const { setFillingForm } = props;
	const { nextPost, prevPost } = props;
	const { post } = props;

	const CurPost = post;
	const previewRef = React.createRef();

	React.useEffect(() => {
		let timer1, timer2;
		if (previewRef?.current)
			timer1 = setTimeout(
				() =>
					handleScroll({
						target: previewRef?.current?.querySelector(
							".post-cont"
						),
					}),
				100
			);
		//timeout to avoid infinite re-render + dependencies warn message
		if (firstShow) timer2 = setTimeout(() => setFirstShow(false), 10);
		window.addEventListener("resize", handleResize);
		return function cleanup() {
			window.removeEventListener("resize", handleResize);
			clearTimeout(timer1);
			clearTimeout(timer2);
		};
	}, []);

	function toggleExtend() {
		document.querySelector(".post-preview").scrollTop = 0;
		if (document.body.clientWidth < 1200) {
			setIsExtended(true);
			if (props.resetCur) props.resetCur();
		} else {
			if (props.forceExtend && isExtended && props.resetCur)
				props.resetCur();
			setIsExtended(!isExtended);
		}
	}

	const toggleDocsExtend = () => setIsDocsExtended(!isDocsExtended);

	const toggleStats = () => setIsStatsExtended(!isStatsExtended);

	function handleResize() {
		if (
			props.responsive !== false &&
			document.body.clientWidth <= 1200 &&
			CurPost &&
			!isExtended &&
			props.resetCur
		)
			props.resetCur();
		if (props.responsive !== false && previewRef?.current)
			handleScroll({
				target: previewRef.current.querySelector(".post-cont"),
			});
	}

	function handleScroll(e) {
		if (!previewRef?.current) return false;
		let cont = previewRef.current;
		let topbar = cont.querySelector(".PostPreviewTopBar");
		let title = cont.querySelector(".PostContent .post-title");
		let scrollTop = e.target?.scrollTop;
		if (!topbar || !title) return false;
		if (
			scrollTop > title.offsetTop - topbar.offsetHeight / 2 ||
			title.offsetTop >
			e.target?.offsetHeight +
			scrollTop +
			title.offsetHeight +
			topbar.offsetHeight
		)
			topbar.classList.add("show-title");
		else topbar.classList.remove("show-title");
	}

	function canShowComments() {
		let allowed_types = [4, 5, 14];
		if (allowed_types.indexOf(CurPost.PostTypeId) === -1) return false;
		if (CurPost.EmployesId === account.EmployesId || isAdmin()) return true;
		return false;
	}

	function canComment() {
		let allowed_types = [4, 5, 14];
		if (
			allowed_types.indexOf(CurPost.PostTypeId) === -1 ||
			CurPost.EmployesId === account.EmployesId
		)
			return false;
		return true;
	}

	const anims = {
		hidden: {
			top: "100vh",
			opacity: 1,
		},
		show: {
			top: "0vh",
			opacity: 1,
			transition: {
				ease: "easeOut",
				duration: 0.5,
			},
		},
	};

	let extend = props.extend !== false ? true : false;

	let force_extend = isExtended || document.body.clientWidth <= 1200;
	return (
		<motion.div
			variants={anims}
			initial={firstShow ? "hidden" : "show"}
			animate="show"
			key={CurPost?.PostId}
			className={
				`post-preview-cont${isExtended && props.extend !== false ? " extended" : ""
				}` +
				`${props.bottombar === false ? " no-bottom-bar" : ""}` +
				`${props.responsive === false ? " no-responsive" : ""}` +
				`${props.extend === false ? " no-extend" : ""}` +
				`${props.float && !force_extend
					? ` floating float-${props.float}`
					: ""
				}` +
				`${props.className ? " " + props.className : ""}`
			}
			onClick={(e) => (props.onClick ? props.onClick(e) : false)}
		>
			{/* <PostMetaTags post={CurPost} companyId={account.CompanyId}/> */}
			<div
				ref={previewRef}
				className={
					"post-preview" +
					(isImagesExtended ? " images-extended" : "")
				}
			>
				{props.float && !force_extend && (
					<div className="mb-2">
						<BackButton
							onClick={props.resetCur}
							value={t("Commons.CLOSE")}
						/>
					</div>
				)}
				{props.topbar !== false &&
					CurPost &&
					(isExtended || document.body.clientWidth <= 1200) && (
						<PostPreviewTopBar
							nextPost={nextPost}
							prevPost={prevPost}
							close={() => toggleExtend(false)}
							open={props.open}
							post={CurPost}
						/>
					)}
				<div
					className="post-cont h-100 d-flex flex-column gap-2"
					onScroll={handleScroll}
				>
					{props.dispImages !== false && (
						<PostImages
							async={props.asyncImages}
							postId={CurPost.PostId}
							companyId={account.CompanyId}
							postTypeId={CurPost.PostTypeId}
							post={CurPost}
							handleExtend={setIsImagesExtended}
							extend={props.extendImages !== false ? true : false}
							images={props.images}
						/>
					)}
					<div className="d-flex flex-column post-inner-cont gap-2">
						{props.editButtons && (
							<EditButtons
								typeId={CurPost.PostTypeId}
								type={props.type && props.type}
								dispState={props.dispState}
								post={CurPost}
								resetCur={props.resetCur}
								handleModify={props.handleModify}
								handlePublish={props.handlePublish}
								handleReject={props.handleReject}
								handleDuplicate={props.handleDuplicate}
								canModify={props.canModify}
								platform={props.platform}
								moderation={props.moderation}
							/>
						)}
						<div className="d-flex justify-content-between align-items-center">
							<AuthorPreview
								employeId={CurPost.EmployesId}
								className="posts-list-author-preview mb-0"
								format={[
									"EmployeFirstName EmployeLastName / WFU",
									{
										date: props.animationId
											? false
											: CurPost.PublishedDate,
									},
								]}
								modifiers={{
									EmployeLastName: (item) =>
										item.toUpperCase(),
								}}
							/>
						</div>
						<PostCategories
							animationId={props.animationId}
							post={CurPost}
							categories={props.categories}
						/>
						{CurPost.ModerationComment ? (
							<div className="moderation-comment-cont">
								<div className="font-bold">
									{t("Posts.MODERATION_MSG")}
								</div>
								<Markdown className="moderation-comment">
									{CurPost.ModerationComment}
								</Markdown>
							</div>
						) : (
							false
						)}
						{props.header}
						<div className="post-content w-100 d-flex flex-column gap-2">
							<PostContent post={CurPost} />
							<PostResponse
								postId={CurPost.PostId}
								response={props.response}
							/>
							<PostLinkedForms
								onClick={setFillingForm}
								postId={CurPost.PostId}
								forms={props.forms}
							/>
						</div>
					</div>
				</div>
				{props.bottombar !== false && (
					<>
						<PostPreviewBottomBar
							extend={extend}
							post={CurPost}
							documents={props.documents}
							canComment={props.canComment && canComment()}
							commentBtnTxt={props.commentBtnTxt}
							showComments={
								props.showComments || canShowComments()
							}
							toggleDocsExtend={toggleDocsExtend}
							toggleStats={toggleStats}
							setIsCommentsExtended={setIsCommentsExtended}
							toggleExtend={toggleExtend}
							isExtended={isExtended}
							stats={props.stats}
						/>
						{isCommentsExtended && (
							<PostComments
								postId={CurPost.PostId}
								isOpen={isCommentsExtended}
								onClose={() => setIsCommentsExtended(false)}
								canComment={props.canComment && canComment()}
								showComments={
									props.showComments || canShowComments()
								}
								submitOnEnter={!props.showComments}
							/>
						)}
						{isDocsExtended && (
							<PostDocumentsDrawer
								isDocsExtended={isDocsExtended}
								onClose={() => setIsDocsExtended(false)}
								postId={CurPost.PostId}
								documents={props.documents}
							/>
						)}
						{isStatsExtended && (
							<PostStats
								isStatsExtended={isStatsExtended}
								onClose={() => setIsStatsExtended(false)}
								postId={CurPost.PostId}
							/>
						)}
					</>
				)}
			</div>
		</motion.div>
	);
}

export default PostPreview;
